import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import axios from 'axios';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import SyncLoader from 'react-spinners/SyncLoader';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ExcelButton from '../General/ExcelButton';

dayjs.extend(quarterOfYear);

function PeriodicChart({
  title,
  endpoint,
  isSubpageSelectHidden,
  isSubscribedCheckHidden,
  isSubpageSubscribersbuttonNeeded,
  isMonthlyCheckHidden,
  isExcelButtonHidden,
}) {
  const [data, setData] = useState([]);
  const [subscribedNumbersData, setSubscribedNumbersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [queryParameters, setQueryParameters] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isMonthly, setIsMonthly] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [page, setPage] = useState('');
  const [label, setLabel] = useState('');

  const handleChangeInputType = (event, inputType) => {
    if (!event.target.value) event.target.type = inputType;
  };

  const handleDate = event => {
    const date = dayjs(event.target.value).format('YYYY-MM-DD');
    setQueryParameters({ ...queryParameters, [event.target.name]: date });
  };

  const handleIsMonthly = event => {
    setIsMonthly(event.target.checked);
  };

  const handleIsSubscribed = event => {
    setIsSubscribed(event.target.checked);
  };

  const handlePage = event => {
    setPage(event.target.value);
    setLabel(event.target.options[event.target.selectedIndex].text);
  };

  const handlePeriodData = async () => {
    if (queryParameters?.from > queryParameters?.to) {
      setIsError(true);
      return;
    }
    try {
      setIsError(false);
      setIsLoading(true);
      let response = await axios.get(`${process.env.REACT_APP_DEV_BASEURL}/${endpoint}`, {
        params: isSubpageSelectHidden
          ? { ...queryParameters, isMonthly: +isMonthly, isSubscribed: +isSubscribed }
          : { ...queryParameters, isMonthly: +isMonthly, isSubscribed: +isSubscribed, page },
      });

      if (isSubscribed && isSubpageSubscribersbuttonNeeded) {
        let response = await axios.get(
          `${process.env.REACT_APP_DEV_BASEURL}/totalHits/subscribed`,
          {
            params: {
              ...queryParameters,
              page,
            },
          }
        );

        setSubscribedNumbersData(response.data.results);
      }

      setData(response.data.results);
      setIsLoading(false);
      setQueryParameters([]);
      setIsMonthly(false);
      setIsSubscribed(false);
      setPage('');
    } catch (error) {
      if (error.response) {
        // Request made but the server responded with an error
        console.log(error.response.data);
      } else if (error.request) {
        // Request made but no response is received from the server.
        console.log(error.request);
      } else {
        // Error occured while setting up the request
        console.log('Error', error.message);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await axios.get(`${process.env.REACT_APP_DEV_BASEURL}/${endpoint}`, {
          params: isSubpageSelectHidden
            ? {
                from: dayjs().startOf('quarter').format('YYYY-MM-DD'),
                to: dayjs().format('YYYY-MM-DD'),
                isMonthly: 0,
                isSubscribed: 0,
              }
            : {
                from: dayjs().startOf('quarter').format('YYYY-MM-DD'),
                to: dayjs().format('YYYY-MM-DD'),
                isMonthly: 0,
                isSubscribed: 0,
                page: '/',
              },
        });

        setData(response.data.results);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          // Request made but the server responded with an error
          console.log(error.response.data);
        } else if (error.request) {
          // Request made but no response is received from the server.
          console.log(error.request);
        } else {
          // Error occured while setting up the request
          console.log('Error', error.message);
        }
      }
    }

    fetchData();
  }, []);

  return isLoading ? (
    <>
      <div className='wrapper'>
        <SyncLoader color='#8884d8' />
      </div>
    </>
  ) : (
    <>
      <div className='wrapper'>
        <div className='chartContainer mx-auto bg-white'>
          <Row className='m-0'>
            <Col className='py-2'>
              <span className='chartTitle mt-4'>{title}</span>
            </Col>
          </Row>
          <Row className='justify-content-center mx-0 bg-white'>
            <InputGroup
              className={`${!isSubscribedCheckHidden ? 'w-100' : 'w-75'} align-items-baseline`}>
              <Form.Control
                aria-label='From'
                type='text'
                id='from'
                name='from'
                className='mx-1'
                onChange={handleDate}
                onFocus={event => handleChangeInputType(event, 'date')}
                onBlur={event => handleChangeInputType(event, 'text')}
                placeholder='From'
                max={dayjs().format('YYYY-MM-DD')}
              />
              <Form.Control
                aria-label='To'
                type='text'
                id='to'
                name='to'
                className='mx-1'
                onChange={handleDate}
                onFocus={event => handleChangeInputType(event, 'date')}
                onBlur={event => handleChangeInputType(event, 'text')}
                placeholder='To'
                max={dayjs().format('YYYY-MM-DD')}
              />
              <Form.Select
                aria-label='Default select example'
                className={isSubpageSelectHidden ? 'd-none' : ''}
                name='page'
                onChange={handlePage}>
                <option>Select Subpage</option>
                <option value='/local/team/6631/overview'>Ahly</option>
                <option value='/local/team/6633'>Zamalek</option>
                <option value='/international/team/11'>Barcelona</option>
                <option value='/international/team/139'>Real Madrid</option>
                <option value='/international/team/4817'>Man city</option>
                <option value='/international/team/138'>Man united</option>
                <option value='/'>Home</option>
                <option value='/fitness/gym-workout'>Gym Workout</option>
                <option value='/fitness/home-workout'>Home Workout</option>
                <option value='/news'>News</option>
                <option value='/tournaments'>Tournaments</option>
                <option value='/matches'>Matches</option>
                <option value='/othersports/handball'>Handball</option>
                <option value='/othersports/tennis'>Tennis</option>
                <option value='/othersports/squash'>Squash</option>
                <option value='/meloukelkora/home'>Melouk</option>
                <option value='/international'>Kora Aalamya</option>
                <option value='/local'>Kora Mahalyya</option>
                {/* <option value='/search'>Search</option> */}
              </Form.Select>
              {!isMonthlyCheckHidden && (
                <Form.Check
                  type='checkbox'
                  name='isMonthly'
                  id={`monthly-radio`}
                  label='Monthly'
                  className='mx-2'
                  onChange={handleIsMonthly}
                />
              )}
              {!isSubscribedCheckHidden && (
                <Form.Check
                  type='checkbox'
                  name='isSubscribed'
                  id={`subscribed-radio`}
                  label='Subscribed'
                  className='mx-2'
                  onChange={handleIsSubscribed}
                />
              )}
              <Button
                variant='secondary'
                id='button-addon1'
                onClick={handlePeriodData}
                className='mx-2'
                disabled={
                  isSubpageSelectHidden
                    ? !(queryParameters?.from && queryParameters?.to)
                    : !(queryParameters?.from && queryParameters?.to && page)
                }>
                Search
              </Button>
              {!isExcelButtonHidden && (
                <ExcelButton
                  data={data}
                  name={title.slice(0,26)}
                  isGreenVariant={true}
                  firstColumn='Day/Month'
                  secondColumn='Count'
                  isOtherfieldsPresent={false}
                />
              )}
              {isSubpageSubscribersbuttonNeeded && subscribedNumbersData && (
                <ExcelButton
                  data={subscribedNumbersData}
                  name={`${label} subs`}
                  isGreenVariant={false}
                  firstColumn='msisdn'
                  secondColumn='Count'
                  isOtherfieldsPresent={false}
                />
              )}
            </InputGroup>
          </Row>
          <Row className='justify-content-center'>
            {isError && (
              <Alert
                className='w-50 my-1'
                variant='danger'
                onClose={() => setIsError(false)}
                dismissible>
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p>"From" date should be less than "To" date, please try again.</p>
              </Alert>
            )}
          </Row>
          <div className='chartContainer mx-auto'>
            {data.length > 0 ? (
              <ResponsiveContainer width='100%' height='100%'>
                <LineChart
                  width={1200}
                  height={600}
                  data={data}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 30,
                    bottom: 20,
                  }}>
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='date' />
                  <YAxis />
                  <Tooltip />
                  <Line type='monotone' dataKey='Count' stroke='#8884d8' />
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <Row className='m-0 h-75 align-content-center my-5'>
                <p className='fs-5 fw-bold mt-4'>sorry, no data available to show right now</p>
              </Row>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PeriodicChart;
