import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import SyncLoader from 'react-spinners/SyncLoader';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import ExcelButton from '../General/ExcelButton';

function TotalCount({ endpoint, title, isSearchable, isMatchesData }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [queryParameters, setQueryParameters] = useState([]);
  const [isError, setIsError] = useState(false);

  const handleChangeInputType = (event, inputType) => {
    if (!event.target.value) event.target.type = inputType;
  };

  const handleDate = event => {
    const date = dayjs(event.target.value).format('YYYY-MM-DD');
    setQueryParameters({ ...queryParameters, [event.target.name]: date });
  };

  const handlePeriodData = async () => {
    if (queryParameters?.from > queryParameters?.to) {
      setIsError(true);
      return;
    }
    try {
      setIsError(false);
      setIsLoading(true);
      let response = await axios.get(`${process.env.REACT_APP_DEV_BASEURL}/${endpoint}`, {
        params: { ...queryParameters },
      });

      setData(response.data.results);
      setIsLoading(false);
      setQueryParameters([]);
    } catch (error) {
      if (error.response) {
        // Request made but the server responded with an error
        console.log(error.response.data);
      } else if (error.request) {
        // Request made but no response is received from the server.
        console.log(error.request);
      } else {
        // Error occured while setting up the request
        console.log('Error', error.message);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await axios.get(`${process.env.REACT_APP_DEV_BASEURL}/${endpoint}`, {
          params: {
            from: dayjs().subtract(6, 'months').format('YYYY-MM-DD'),
            to: dayjs().format('YYYY-MM-DD'),
          },
        });

        setData(response.data.results);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          // Request made but the server responded with an error
          console.log(error.response.data);
        } else if (error.request) {
          // Request made but no response is received from the server.
          console.log(error.request);
        } else {
          // Error occured while setting up the request
          console.log('Error', error.message);
        }
      }
    }

    fetchData();
  }, []);

  return isLoading ? (
    <>
      <div className='wrapper'>
        <SyncLoader color='#8884d8' />
      </div>
    </>
  ) : (
    <>
      <div className='wrapper shadowed'>
        <Row className='mx-auto'>
          <Card className='border-0 my-0'>
            <Card.Body>
              <p className='chartTitle mt-2'>{title}</p>
              {isSearchable && (
                <Row className='justify-content-center mx-0 bg-white'>
                  <InputGroup className='align-items-baseline'>
                    <Form.Control
                      aria-label='From'
                      type='text'
                      id='from'
                      name='from'
                      className='mx-1'
                      onChange={handleDate}
                      onFocus={event => handleChangeInputType(event, 'date')}
                      onBlur={event => handleChangeInputType(event, 'text')}
                      placeholder='From'
                      max={dayjs().format('YYYY-MM-DD')}
                    />
                    <Form.Control
                      aria-label='To'
                      type='text'
                      id='to'
                      name='to'
                      className='mx-1'
                      onChange={handleDate}
                      onFocus={event => handleChangeInputType(event, 'date')}
                      onBlur={event => handleChangeInputType(event, 'text')}
                      placeholder='To'
                      max={dayjs().format('YYYY-MM-DD')}
                    />
                    <Button
                      variant='secondary'
                      id='button-addon1'
                      onClick={handlePeriodData}
                      className='mx-2'
                      disabled={!(queryParameters?.from && queryParameters?.to)}>
                      Search
                    </Button>
                  </InputGroup>
                </Row>
              )}
              {isMatchesData && data?.length > 0 && (
                <ExcelButton
                  data={data}
                  name={title}
                  isGreenVariant={true}
                  firstColumn='title'
                  secondColumn='Count'
                  isOtherfieldsPresent={false}
                  isMatchesData={true}
                />
              )}
              {!isMatchesData ? (
                <Card.Text className='py-5 fw-bold fs-2'>{data}</Card.Text>
              ) : (
                <Card.Text className='py-5 fw-bold fs-2'>{data?.length}</Card.Text>
              )}
            </Card.Body>
          </Card>
        </Row>
      </div>
    </>
  );
}

export default TotalCount;
